import { NextComponentType, NextPageContext } from "next";
import Router from "next/router";
import NProgress from "nprogress";

import { ProvideCart } from "../context/cart/CartProvider";
import { ProvideWishlist } from "../context/wishlist/WishlistProvider";

import "../styles/globals.css";
import "nprogress/nprogress.css";

// Import Swiper styles
import "swiper/css"; // core Swiper
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "animate.css";
import "react-toastify/dist/ReactToastify.css";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

type AppCustomProps = {
  Component: NextComponentType<NextPageContext, any, {}>;
  pageProps: any;
  cartState: string;
  wishlistState: string;
};

const MyApp = ({ Component, pageProps }: AppCustomProps) => {
  return (
    <ProvideWishlist>
      <ProvideCart>
        <Component {...pageProps} />
      </ProvideCart>
    </ProvideWishlist>
  );
};

export default MyApp;
